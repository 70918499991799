import React, { useEffect, useState, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { PropTypes } from 'prop-types';
import injectSheet from 'react-jss';
import { Loading, Button } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import DataTableManagerNavigation from './dtm-navigation';
import NewDataTableProfile from './new-data-table-profile';
import DataTableColumnsTable from './data-table-columns-table';
import { CUSTOMERS_AND_DATA_TABLE_PROFILES, DATA_TABLE_PROFILE_FIELDS_QUERY, SAVE_DATA_TABLE_PROFILE_MUTATION } from './dtm-queries';
import ETLImportErrors from '../import-manager/etl-import-errors';
import DataTableProfileUploader from './data-table-profile-uploader';
import ConfirmationModal from '../../components/modals/profile-upload-confirmation-modal';
import AppSettings from '../../app-settings';

const styles = {
  wrap: {
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 25,
    alignItems: 'center',
  },
  saveWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  validation: {
    marginRight: 25,
  },
  pageButtons: {
    display: 'flex',
    flexDirection: 'row',
    '&>button': {
      marginLeft: 10,
    },
  },
};

const DataTableManager = ({ classes }) => {
  const [site, setSite] = useState({ _id: null, siteName: null });
  const [tableType, setTableType] = useState(null);
  const [matchingReferenceProfiles, setMatchingReferenceProfiles] = useState([]);
  const [referenceProfile, setReferenceProfile] = useState(null);
  const [activeProfile, setActiveProfile] = useState(null);
  const [errorList, setErrorList] = useState([]);
  const [confirmUpload, setConfirmUpload] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const editingColumns = useRef(null);

  // queries and mutations
  const { data: headerData } = useQuery(CUSTOMERS_AND_DATA_TABLE_PROFILES);
  const { data: profileFields, loading: loadingFields } = useQuery(DATA_TABLE_PROFILE_FIELDS_QUERY, {
    variables: {
      dataTableProfileId: referenceProfile?.id,
    },
    skip: !referenceProfile,
    fetchPolicy: 'no-cache',
  });
  const [saveProfile, { data: saveProfileData, loading: saveProfileLoading, error: saveProfileError }] =
    useMutation(SAVE_DATA_TABLE_PROFILE_MUTATION);

  const sites = headerData?.dataTableManagerQuery?.allSellerOrgs;
  const tableTypes = headerData?.dataTableManagerQuery?.possibleTableTypes;
  const existingDataTableProfiles = headerData?.dataTableManagerQuery?.dataTableProfiles;

  useEffect(() => {
    TitleService.setTitles('Data Table Manager');
    Breadcrumbs.set([
      {
        url: '/table-manager',
        title: 'Data Table Manager',
      },
    ]);
  });

  useEffect(() => {
    if (site && tableType) {
      const matchingProfile = existingDataTableProfiles.find((p) => p.siteId === site._id && p.dataTableName === tableType);
      setActiveProfile(matchingProfile);
      setReferenceProfile(matchingProfile);
      setMatchingReferenceProfiles(existingDataTableProfiles.filter((p) => p.dataTableName === tableType));
    } else {
      setActiveProfile(null);
      setMatchingReferenceProfiles([]);
      setReferenceProfile(null);
    }
  }, [site, tableType]);

  useEffect(() => {
    if (saveProfileData) {
      setActiveProfile(saveProfileData.SaveDataTableProfile);
      setReferenceProfile(saveProfileData.SaveDataTableProfile);
    }
  }, [saveProfileData]);

  const save = () => {
    saveProfile({
      variables: {
        dataTableProfile: {
          id: activeProfile?.id,
          siteName: site.siteName,
          siteId: site._id,
          dataTableName: tableType,
          columns: editingColumns.current?.map((c) => {
            c.__typename = undefined;
            c.key = undefined;
            return c;
          }),
          dataTableType: referenceProfile.dataTableType,
        },
      },
      refetchQueries: ['getSitesAndDataTableProfiles'],
    });
  };

  const downloadDataTableProfile = () => {
    const profile = profileFields?.dataTableManagerQuery?.dataTableProfile;
    const cleanProfile = {
      id: profile.id,
      siteId: profile.siteId,
      siteName: profile.siteName,
      dataTableName: profile.dataTableName,
      dataTableType: profile.dataTableType,
      columns: profile.columns.map((c) => {
        return {
          field: c.field,
          displayName: c.displayName,
          visible: c.visible,
          sortable: c.sortable === null ? undefined : c.sortable,
          defaultSort: c.defaultSort === null ? undefined : c.defaultSort,
          defaultSortDirection: c.defaultSortDirection === null ? undefined : c.defaultSortDirection,
          formatter: c.formatter === null ? undefined : c.formatter,
        };
      }),
    };
    const blob = new Blob([JSON.stringify(cleanProfile, null, 2)], { type: 'application/json' });
    const link = document.createElement('a');
    link.download = `${AppSettings.ENVIRONMENT_NAME}_${profile.siteName}_${profile.dataTableName}_${new Date()}.json`;
    link.href = URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const uploadDataTableProfile = () => {
    setConfirmUpload(true);
  };

  const tableLoading = () => loadingFields;

  const renderView = () => {
    if (!tableType) {
      return <div></div>;
    }
    if (tableType != null && !referenceProfile) {
      return (
        <NewDataTableProfile
          site={site}
          tableType={tableType}
          possibleDataTableProfiles={matchingReferenceProfiles}
          referenceProfile={referenceProfile}
          setReferenceProfile={setReferenceProfile}
        />
      );
    }
    if (referenceProfile) {
      return (
        <div>
          <DataTableColumnsTable
            possibleFields={profileFields?.dataTableManagerQuery.dataTableProfile.possibleFields}
            columns={profileFields?.dataTableManagerQuery.dataTableProfile.columns}
            loading={loadingFields}
            editingColumns={editingColumns}
            tableType={tableType}
            setErrorList={setErrorList}
          />
          <ConfirmationModal setConfirmUpload={setConfirmUpload} setShowUpload={setShowUpload} confirmUpload={confirmUpload} />
          {showUpload && <DataTableProfileUploader setShowUpload={setShowUpload} saveProfile={saveProfile} />}
        </div>
      );
    }
    return <div></div>;
  };

  if (saveProfileError) return `Something went wrong when saving. Please contact engineering. ${saveProfileError.message}`;
  return (
    <div className={classes.wrap}>
      <header className={classes.header}>
        <DataTableManagerNavigation
          sites={sites}
          site={site}
          setSite={setSite}
          tableTypes={tableTypes}
          tableType={tableType}
          setTableType={setTableType}
        />
        {referenceProfile && (
          <div className={classes.saveWrap}>
            <div className={classes.validation}>
              <ETLImportErrors showCount={true} showLabel={true} errors={errorList} />
            </div>
            <div className={classes.pageButtons}>
              <Button primary onClick={uploadDataTableProfile} disabled={errorList.length > 0}>
                Upload Profile
              </Button>
              <Button primary onClick={downloadDataTableProfile} disabled={errorList.length > 0}>
                Download Profile
              </Button>
              <Button primary onClick={save} disabled={errorList.length > 0} loading={saveProfileLoading} loadingText={'Saving'}>
                Save
              </Button>
            </div>
          </div>
        )}
      </header>
      <Loading loading={tableLoading()}>{renderView()}</Loading>
    </div>
  );
};

DataTableManager.propTypes = {
  classes: PropTypes.object,
};

export default injectSheet(styles)(DataTableManager);
