import PropTypes from 'prop-types';

const ImportProfileUploader = ({ saveProfile }) => {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = '.json';
  fileInput.onchange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (ev) => {
      const profile = JSON.parse(ev.target.result);
      saveProfile({
        variables: {
          profile,
        },
        refetchQueries: ['getSitesAndExportProfiles', 'getFieldsByExportProfileId'],
      });
    };
    reader.readAsText(file);
  };
  fileInput.click();
  return null;
};

ImportProfileUploader.propTypes = {
  saveProfile: PropTypes.func,
  saveMappings: PropTypes.func,
};

export default ImportProfileUploader;
