import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { WindowScroller, AutoSizer } from 'react-virtualized';
import { Checkbox } from '@spoiler-alert/ui-library';
import { useStyles } from './v-styles';
import VInnerTable from './v-inner-table';
import VColumn from './v-column';
import { getData, getVTableColumnsFromDataTableProfile } from './columns-helpers';

const VTable = ({
  data: filteredTableData,
  columnOverrides,
  cypressTagTable,
  checkboxes,
  searchText,
  profileName,
  userSite,
  checkedRows,
  onRowCheck,
  scrollContainer,
  rowActions,
  visibleRowsRef,
  cols,
  ...rest
}) => {
  const classes = useStyles(rest);

  const checkboxColumn = useMemo(() => {
    if (!checkboxes) return null;
    return new VColumn({
      field: '_id',
      displayName: '',
      sortable: false,
      resizable: false,
      width: 33,
      formatter: (value) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Checkbox value={value} name={value} checked={Array.isArray(checkedRows) && checkedRows.includes(value)} onChecked={onRowCheck} />
        </div>
      ),
    });
  }, [checkboxes, checkedRows, onRowCheck]);

  const rowActionsColumn = useMemo(() => {
    if (!rowActions || rowActions.length === 0) return null;
    const width = (36 + 8) * rowActions.length;
    return new VColumn({
      field: '_id',
      displayName: '',
      sortable: false,
      resizable: false,
      width,
      style: { height: 36 },
      // eslint-disable-next-line react/display-name
      formatter: (_, row) => (
        <div className={classes.rowActionContainer}>
          {rowActions.map((child) => React.cloneElement(child, { row, className: classes.rowAction }))}
        </div>
      ),
    });
  }, [rowActions, classes]);

  const { columns, hiddenColumns } = useMemo(() => {
    if (cols) {
      const _columns = cols;
      if (checkboxes) _columns.unshift(checkboxColumn);
      if (rowActionsColumn) _columns.push(rowActionsColumn);
      return {
        columns: _columns,
        hiddenColumns: [],
      };
    }
    const { visible, hidden } = getVTableColumnsFromDataTableProfile(profileName, userSite, columnOverrides);
    const _columns = [...visible];
    if (checkboxes) _columns.unshift(checkboxColumn);
    if (rowActionsColumn) _columns.push(rowActionsColumn);
    return {
      columns: _columns,
      hiddenColumns: hidden,
    };
  }, [userSite.dataTableProfiles, profileName, checkboxes, checkboxColumn, rowActionsColumn]);

  const searchedTableData = useMemo(() => {
    const search = searchText?.trim();
    if (!search || search === '') return [...filteredTableData];
    const searchRegex = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
    const searchableColumns = [...columns, ...hiddenColumns];
    return filteredTableData.filter((row) => searchableColumns.some((col) => searchRegex.test(getData(row, col.field))));
  }, [filteredTableData, searchText]);

  useEffect(() => {
    if (visibleRowsRef && onRowCheck && visibleRowsRef.current.length !== searchedTableData.length) {
      visibleRowsRef.current = searchedTableData.map((row) => row._id);
      onRowCheck({ refresh: true });
    }
  }, [searchedTableData.length]);

  const scroller = scrollContainer || window;

  return (
    <div className={classes.table} data-cy={cypressTagTable}>
      {scroller && (
        <WindowScroller scrollElement={scroller}>
          {({ height, onChildScroll, isScrolling, scrollTop }) => (
            <AutoSizer disableHeight>
              {({ width }) =>
                height &&
                width && (
                  <VInnerTable
                    key={userSite._id}
                    width={width}
                    height={height}
                    columns={columns}
                    data={searchedTableData}
                    userSite={userSite}
                    profileName={profileName}
                    onChildScroll={onChildScroll}
                    isScrolling={isScrolling}
                    scrollTop={scrollTop}
                    {...rest}
                  />
                )
              }
            </AutoSizer>
          )}
        </WindowScroller>
      )}
    </div>
  );
};

VTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  noDataMessage: PropTypes.node,
  cols: PropTypes.array,
  profileName: PropTypes.string,
  userSite: PropTypes.object,
  columnOverrides: PropTypes.object,

  checkboxes: PropTypes.bool,
  checkedRows: PropTypes.arrayOf(PropTypes.string),
  onRowCheck: PropTypes.func,
  visibleRowsRef: PropTypes.shape({ current: PropTypes.arrayOf(PropTypes.string) }),
  rowActions: PropTypes.array,

  onRowClick: PropTypes.func,
  highlightRowId: PropTypes.string,

  searchText: PropTypes.string,
  keyboardNavigation: PropTypes.bool,
  border: PropTypes.bool,
  sticky: PropTypes.bool,
  stickyOffset: PropTypes.number,
  scrollContainer: PropTypes.instanceOf(Element),
  cypressTagTable: PropTypes.string,
};

export default VTable;
