import PropTypes from 'prop-types';

const DataTableProfileUploader = ({ saveProfile }) => {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = '.json';
  fileInput.onchange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (ev) => {
      const profile = JSON.parse(ev.target.result);
      const cleanProfile = {
        id: profile.id,
        siteId: profile.siteId,
        siteName: profile.siteName,
        dataTableName: profile.dataTableName,
        dataTableType: profile.dataTableType,
        columns: profile.columns.map((c) => {
          return {
            field: c.field,
            displayName: c.displayName,
            visible: c.visible,
            sortable: c.sortable === null ? undefined : c.sortable,
            defaultSort: c.defaultSort === null ? undefined : c.defaultSort,
            defaultSortDirection: c.defaultSortDirection === null ? undefined : c.defaultSortDirection,
            formatter: c.formatter === null ? undefined : c.formatter,
          };
        }),
      };
      saveProfile({
        variables: {
          dataTableProfile: cleanProfile,
        },
        refetchQueries: ['getSitesAndDataTableProfiles', 'getColumnsByDataTableProfileId'],
      });
    };
    reader.readAsText(file);
  };
  fileInput.click();
  return null;
};

DataTableProfileUploader.propTypes = {
  saveProfile: PropTypes.func,
};

export default DataTableProfileUploader;
