import { fetchWithToken } from './fetch-with-token';
import AppSettings from '../app-settings';

class SheetUploadService {
  static async importSheet(file, path) {
    try {
      const name = path.split('\\').pop();
      const importUrl = `${AppSettings.GRAPHQL_SERVER_URL}admin-inventory-updates`;
      const body = new FormData();
      body.append('file', file);
      body.append('name', name);
      const response = await fetchWithToken(importUrl, {
        body,
        method: 'POST',
      });
      if (response.status === 200) {
        const res = await response.json();
        return { success: true, result: res };
      }
      throw new Error(await response.text());
    } catch (error) {
      return { success: false, errorMessage: error.message };
    }
  }
}

export default SheetUploadService;
