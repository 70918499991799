import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import injectSheet from 'react-jss';
import { Button, Loading, AlertService } from '@spoiler-alert/ui-library';
import { Breadcrumbs } from '../../store';
import { TitleService } from '../../services';
import IMPORT_TYPES from './enums/etl-import-types';
import ETLNavigation from './etl-navigation';
import ETLView from './etl-view';
import ETLNewProfile from './etl-new-profile';
import {
  IMPORT_PROFILE_FIELDS_QUERY,
  VALIDATE_IMPORT_PROFILE_MUTATION,
  CREATE_IMPORT_PROFILE_MUTATION,
  CREATE_IMPORT_MAPPING_MUTATION,
  CREATE_IMPORT_DEFAULT_MUTATION,
  UPDATE_IMPORT_DEFAULT_MUTATION,
  UPDATE_IMPORT_MAPPING_MUTATION,
  UPDATE_IMPORT_PROFILE_MUTATION,
  UPDATE_IMPORT_PROFILE_WITH_MAPPINGS_AND_DEFAULTS_MUTATION,
  DELETE_IMPORT_DEFAULT_MUTATION,
  DELETE_IMPORT_MAPPING_MUTATION,
} from './etl-import-queries';
import ETLEditProfileModal from './etl-edit-profile-modal';
import ETLErrors from './etl-errors';
import ETLImportErrors from './etl-import-errors';
import ConfirmationModal from '../../components/modals/profile-upload-confirmation-modal';
import ImportProfileUploader from './import-profile-uploader';
import AppSettings from '../../app-settings';

const styles = {
  wrap: {
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 25,
    alignItems: 'center',
  },
  pageButtons: {
    display: 'flex',
    '& button': {
      marginLeft: 16,
    },
  },
  '@media (max-width: 1400px)': {
    pageButtons: {
      display: 'flex',
      '& button': {
        margin: [16, 16, 0, 0],
      },
    },
  },
};

const ImportManager = ({ classes }) => {
  const [importType, setImportType] = useState(IMPORT_TYPES.ITEM);
  const [site, setSite] = useState({ _id: null, siteName: null, importProfileName: null });
  const [importProfile, setImportProfile] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [confirmUpload, setConfirmUpload] = useState(false);
  const [showUpload, setShowUpload] = useState(false);

  useEffect(() => {
    TitleService.setTitles('Import Manager');
    Breadcrumbs.set([
      {
        url: '/import-manager',
        title: 'Import Manager',
      },
    ]);
  });

  const {
    data: profileFields,
    loading: loadingFields,
    error: loadFieldsError,
  } = useQuery(IMPORT_PROFILE_FIELDS_QUERY, {
    variables: {
      importProfileId: importProfile?._id,
    },
    skip: !importProfile,
  });

  const [createImportProfile, { loading: creatingProfile, error: createProfileError }] = useMutation(CREATE_IMPORT_PROFILE_MUTATION, {
    onCompleted: (data) => {
      setImportProfile(data.createNewImportProfile);
    },
    refetchQueries: ['getSitesAndImportProfiles'],
  });
  const [createImportMapping, { loading: creatingMapping, error: createMappingError }] = useMutation(CREATE_IMPORT_MAPPING_MUTATION);
  const [createImportDefault, { loading: creatingDefault, error: createDefaultError }] = useMutation(CREATE_IMPORT_DEFAULT_MUTATION);
  const [updateImportProfile, { loading: savingProfile, error: saveProfileError }] = useMutation(UPDATE_IMPORT_PROFILE_MUTATION);
  const [updateImportProfileWithMappingsAndDefaults] = useMutation(UPDATE_IMPORT_PROFILE_WITH_MAPPINGS_AND_DEFAULTS_MUTATION);
  const [updateImportMapping, { data: updatedMapping, loading: savingMapping, error: saveMappingError }] =
    useMutation(UPDATE_IMPORT_MAPPING_MUTATION);
  const [updateImportDefault, { data: updatedDefault, loading: savingDefault, error: saveDefaultError }] =
    useMutation(UPDATE_IMPORT_DEFAULT_MUTATION);
  const [deleteImportMapping, { loading: deletingMapping, error: deleteMappingError }] = useMutation(DELETE_IMPORT_MAPPING_MUTATION);
  const [deleteImportDefault, { loading: deletingDefault, error: deleteDefaultError }] = useMutation(DELETE_IMPORT_DEFAULT_MUTATION);
  const [validateImportProfile, { loading: validatingProfile, error: validateProfileError }] = useMutation(VALIDATE_IMPORT_PROFILE_MUTATION);

  const mutations = {
    createImportProfile,
    createImportMapping,
    createImportDefault,
    updateImportProfile,
    updateImportMapping,
    updateImportDefault,
    deleteImportMapping,
    deleteImportDefault,
  };

  const loadings = {
    creatingMapping,
    creatingDefault,
    savingProfile,
    savingMapping,
    savingDefault,
    deletingMapping,
    deletingDefault,
  };

  useEffect(() => {
    if (savingProfile || savingMapping || savingDefault || deletingMapping || deletingDefault) {
      setSaveInProgress(true);
    } else {
      setSaveInProgress(false);
    }
  }, [savingProfile, savingMapping, savingDefault, deletingMapping, deletingDefault]);

  const recentlyUpdated = {
    updatedMapping,
    updatedDefault,
  };

  const createNewInventoryImport = () => {
    setImportType(IMPORT_TYPES.INVENTORY);
    setImportProfile(null);
  };

  const errors = [
    loadFieldsError,
    createProfileError,
    createMappingError,
    createDefaultError,
    saveProfileError,
    saveMappingError,
    saveDefaultError,
    deleteMappingError,
    deleteDefaultError,
    validateProfileError,
  ];

  const importErrors = profileFields?.importManagerQuery.importProfile.importErrors;

  const loading = () => loadingFields || creatingProfile;

  const downloadImportProfile = () => {
    if (importProfile.validated === false) {
      AlertService.alert({ type: 'warning', message: 'Please validate the profile before downloading it.' });
      return;
    }
    const cleanProfile = {
      _id: importProfile._id,
      siteId: importProfile.siteId,
      importType: importProfile.importType,
      importName: importProfile.importName === null ? undefined : importProfile.importName,
      delimiter: importProfile.delimiter,
      useRootSiteForItems: importProfile.useRootSiteForItems === null ? undefined : importProfile.useRootSiteForItems,
      headerNames: importProfile.headerNames,
      dateFormats: importProfile.dateFormats,
      validated: importProfile.validated,
      importErrors: importProfile.importErrors,
      bypassItemValidation: importProfile.bypassItemValidation,
      createdBy: importProfile.createdBy,
    };
    const cleanMappings = profileFields.importManagerQuery.importProfile.mappings.map((mapping) => {
      return {
        _id: mapping._id,
        saName: mapping.saName,
        externalName: mapping.externalName === null ? undefined : mapping.externalName,
        intermediateHeaderName: mapping.intermediateHeaderName === null ? undefined : mapping.intermediateHeaderName,
        dataType: mapping.dataType === null ? undefined : mapping.dataType,
        dateFormats: mapping.dateFormats === null ? undefined : mapping.dateFormats,
        required: mapping.required === null ? undefined : mapping.required,
        validators: mapping.validators,
        padStart: mapping.padStart === null ? undefined : mapping.padStart,
        operation:
          mapping.operation === null
            ? undefined
            : {
                operator: mapping.operation.operator,
                params: mapping.operation.params,
                staticParams: mapping.operation.staticParams,
              },
        importProfileId: mapping.importProfileId,
        order: mapping.order,
        importErrors: mapping.importErrors,
        createdBy: mapping.createdBy,
      };
    });
    const cleanDefaults = profileFields.importManagerQuery.importProfile.defaults.map((defaultVal) => {
      return {
        _id: defaultVal._id,
        saName: defaultVal.saName,
        externalName: defaultVal.externalName === null ? undefined : defaultVal.externalName,
        dataType: defaultVal.dataType,
        defaultValues: defaultVal.defaultValues.map((df) => {
          return {
            defaultValue: df.defaultValue,
            defaultType: df.defaultType,
          };
        }),
        importErrors: defaultVal.importErrors,
        importProfileId: defaultVal.importProfileId,
      };
    });
    const blob = new Blob([JSON.stringify({ importProfile: cleanProfile, importMappings: cleanMappings, importDefaults: cleanDefaults }, null, 2)], {
      type: 'application/json',
    });
    const link = document.createElement('a');
    link.download = `${AppSettings.ENVIRONMENT_NAME}_${site.siteName}_${importProfile.importType}_Import-Profile_${new Date()}.json`;
    link.href = URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const uploadImportProfile = () => {
    setConfirmUpload(true);
  };

  const renderView = () => {
    if (!site._id) {
      return <div></div>;
    }
    if (profileFields) {
      return (
        <div>
          <ETLView
            importProfile={importProfile}
            profileFields={profileFields.importManagerQuery.importProfile}
            mutations={mutations}
            loadings={loadings}
            updated={recentlyUpdated}
            setSaveInProgress={setSaveInProgress}
          />
          <ConfirmationModal setConfirmUpload={setConfirmUpload} setShowUpload={setShowUpload} confirmUpload={confirmUpload} />
          {showUpload && (
            <ImportProfileUploader
              setShowUpload={setShowUpload}
              saveProfile={updateImportProfileWithMappingsAndDefaults}
              saveDefaults={saveDefaultError}
              saveMappings={saveDefaultError}
            />
          )}
        </div>
      );
    }
    if (importProfile === null) {
      return <ETLNewProfile importType={importType} site={site} create={createImportProfile} />;
    }
    return <div></div>;
  };

  return (
    <div className={classes.wrap}>
      <header className={classes.header}>
        <ETLNavigation
          importType={importType}
          setImportType={setImportType}
          site={site}
          setSite={setSite}
          setImportProfile={setImportProfile}
          importProfile={importProfile}
          createNewInventoryImport={createNewInventoryImport}
        />
        <ETLImportErrors errors={importErrors} showCount showLabel />
        {importProfile && (
          <div className={classes.pageButtons}>
            <Button primary onClick={uploadImportProfile} disabled={!!loadFieldsError || loading()}>
              Upload Profile
            </Button>
            <Button primary onClick={downloadImportProfile} disabled={!!loadFieldsError || loading()}>
              Download Profile
            </Button>
            <Button secondary onClick={() => setShowEditModal(true)}>
              Settings
            </Button>
            <Button
              onClick={() => validateImportProfile({ variables: { importProfileId: importProfile._id } })}
              loading={validatingProfile}
              disabled={saveInProgress}
              loadingText="Validating..."
            >
              Validate
            </Button>
          </div>
        )}
      </header>
      <ETLErrors errors={errors} />
      <Loading loading={loading()}>{renderView()}</Loading>
      <ETLEditProfileModal
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        importProfile={importProfile}
        updateImportProfile={updateImportProfile}
        validateImportProfile={validateImportProfile}
      />
    </div>
  );
};

ImportManager.propTypes = {
  classes: PropTypes.object,
};

export default injectSheet(styles)(ImportManager);
